import React from "react";
import { useTranslation } from "react-i18next";
import ResponsiveMedia from "utils/responsive_media";
import DescriptionWidhImage from "../../parts/description/descriptionWithImage";
import VideoTitle from "../../parts/videoTitle";
import Group from "./group";

const Solution = ({ title, linksend, bulksend, group, draft, api, on_premise }) => {
    const { isMobile } = ResponsiveMedia();

    const { i18n } = useTranslation();

    return (
        <>
            <VideoTitle data={title} bgColor={"#fcf4ec"} padding={isMobile === "true" ? "" : "0 0 77px"} />
            <DescriptionWidhImage data={api} imgWidth={"592px"} padding={"130px 40px 130px"} textToTop btnMarginTop={i18n.language === "en" ? "2px" : "0px"} />
            <DescriptionWidhImage data={on_premise} imgWidth={"592px"} padding={"130px 40px 130px"} reverse bgColor={"#f9f9f9"} textToTop btnMarginTop={i18n.language === "en" ? "2px" : "0px"} />
            <DescriptionWidhImage data={bulksend} imgWidth={"624px"} textToTop btnMarginTop={i18n.language === "en" ? "2px" : "0px"} />
            <DescriptionWidhImage data={linksend} imgWidth={"651px"} padding={"130px 40px 122px"} textToTop reverse bgColor={"#f9f9f9"} mobileImgStyle={{ marginLeft: "20px" }} btnMarginTop={i18n.language === "en" ? "2px" : "0px"} />
            <Group data={group} />
            <DescriptionWidhImage data={draft} imgWidth={"624px"} textToTop bgColor={"#f9f9f9"} btnMarginTop={i18n.language === "en" ? "2px" : "0px"} marginBottom={"70px"} />
        </>
    );
};

export default Solution;
